<template>
  <navigation-layout>
    <centered-form-layout
      :title="$t('auth.login')"
      @submit.prevent="submit">
      <form-message
        class="mt-4"
        v-if="error">
        {{ $t('auth.authenticate_error_message') }}
      </form-message>
      <form-row class="mt-4">
        <form-input
          :required="true"
          v-model="username">
          {{ $t('generic.username') }}
        </form-input>
      </form-row>
      <form-row class="mt-4">
        <form-input
          type="password"
          :required="true"
          v-model="password">
          {{ $t('generic.password') }}
        </form-input>
      </form-row>
      <form-row>
        <c-button
          class="mt-4"
          type="submit"
          :disabled="loading">
          {{ $t('auth.login') }}
        </c-button>
        <c-button
          class="mt-2"
          type="button"
          :disabled="loading"
          @click.prevent="loginAsGuest">
          {{ $t('auth.login_as_guest') }}
        </c-button>
      </form-row>
      <form-row class="mt-4">
        <div class="flex justify-between">
          <router-link
            :to="resolve(registerLink)"
            class="text-sm hover:underline">
            {{ $t('auth.register') }}
          </router-link>
          <router-link
            :to="resolve(forgotLink)"
            class="text-sm hover:underline">
            {{ $t('auth.forgot') }}
          </router-link>
        </div>
      </form-row>
    </centered-form-layout>
  </navigation-layout>
</template>

<script>
  import DesignMixin from '../../mixin/design'

  export default {
    computed: {
      registerLink() {
        return this.$route.params.slug
          ? '/' + this.$route.params.slug + '/auth/register'
          : '/auth/register'
      },
      forgotLink() {
        return this.$route.params.slug
          ? '/' + this.$route.params.slug + '/auth/forgot'
          : '/auth/forgot'
      }
    },
    data() {
      return {
        error: false,
        password: null,
        username: null,
        loading: false,
      }
    },
    /**
     * Load client root category and apply design (colors/images).
     * If the a project url has been called the design of it will be used.
     * @return {Promise<void>}
     */
    async created() {
      await this.applyDesign()
      this.$emit('updateUI')

      // Reset credentials if not happend yet
      this.$store.commit('auth/setUser', null)
      this.$store.commit('auth/setAuthCode', null)
    },
    methods: {
      /**
       * Authenticates as a guest
       */
      loginAsGuest() {
        this.username = 'guest'
        this.password = 'guest'
        this.submit()
      },
      /**
       * Authenticates with given credentials
       * @return {Promise<void>}
       */
      async submit() {
        this.error = false
        this.loading = true
        let authResponse = await this.$api.login(this.username, this.password)
        authResponse = await authResponse.json()
        if (authResponse && !authResponse.error) {
          this.$store.commit('auth/setUser', authResponse)
          this.$store.commit('auth/setAuthCode', btoa(`${this.username}:${this.password}`))
          let categoryId = this.$route.params.slug
          return categoryId
            ? await this.go('/' + this.$route.params.slug)
            : await this.go('/')
        }
        this.loading = false
        this.error = true
      },
    },
    inject: [
      '$api'
    ],
    mixins: [
      DesignMixin
    ]
  }
</script>