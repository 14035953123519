<template>
  <navigation-layout>
    <centered-form-layout
      :title="caption"
      @submit.prevent="submit">
      <template v-if="!success">
        <form-message
          class="mt-4"
          v-if="error">
          {{ $t('auth.authenticate_error_message') }}
        </form-message>
        <form-input
          class="mt-4"
          v-model="title">
          {{ $t('generic.title') }}
        </form-input>
        <form-input
          class="mt-4"
          v-model="login">
          {{ $t('auth.login') }}
        </form-input>
        <form-input
          class="mt-4"
          v-model="firstname">
          {{ $t('generic.firstname') }}
        </form-input>
        <form-input
          class="mt-4"
          v-model="lastname">
          {{ $t('generic.lastname') }}
        </form-input>
        <form-input
          class="mt-4"
          type="email"
          v-model="email">
          {{ $t('generic.email') }}
        </form-input>
        <form-input
          class="mt-4"
          v-model="company">
          {{ $t('generic.company') }}
        </form-input>
        <form-input
          class="mt-4"
          type="select"
          v-model="role"
          :values="roles"
          v-if="$route.params.slug">
          {{ $t('generic.role') }}
        </form-input>
        <form-row>
          <c-button
            class="mt-4"
            type="submit"
            :disabled="loading">
            {{ $t('auth.register') }}
          </c-button>
        </form-row>
      </template>
      <template v-else>
        <div class="p-4 text-center">
          <div class="flex justify-center">
            <icon
              size="xxxlarge"
              src="icons/mail.svg">
            </icon>
          </div>
          <div class="mt-4">{{ $t('auth.register_success_message') }}</div>
        </div>
      </template>
    </centered-form-layout>
  </navigation-layout>
</template>

<script>
  import DesignMixin from '../../mixin/design'
  import {mapGetters} from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        baseURI: 'config/baseURI',
        registerRoles: 'config/registerRoles',
        defaultRegisterRole: 'config/defaultRegisterRole',
      }),
      caption() {
        return this.success
          ? this.$t('auth.register_success')
          : this.$t('auth.register')
      },
      roles() {
        return this.registerRoles && this.registerRoles[this.$helper.extractIdFromSlug(this.$route.params.slug)]
          ? this.registerRoles[this.$helper.extractIdFromSlug(this.$route.params.slug)]
          : []
      }
    },
    data() {
      return {
        error: false,
        role: null,
        login: null,
        title: null,
        email: null,
        company: null,
        success: false,
        lastname: null,
        loading: false,
        firstname: null,
      }
    },
    /**
     * Applies design and updates the ui
     * @return {Promise<void>}
     */
    async created() {
      await this.applyDesign()
      this.$emit('updateUI')
    },
    methods: {
      async submit() {
        this.error = false
        this.loading = true
        let path = this.$route.params.slug
          ? `${this.baseURI}/#/${this.$route.params.slug}`
          : `${this.baseURI}/#/`

        const payload = {
          login: this.login,
          email: this.email,
          title: this.title,
          company: this.company,
          lastname: this.lastname,
          firstname: this.firstname,
        }

        // Select role (client specific)
        let role = this.defaultRegisterRole
        const slugId = this.$helper.extractIdFromSlug(this.$route.params.slug)
        if (this.registerRoles
          && this.registerRoles[slugId]) {
          role = this.registerRoles[slugId].find(r => r.id == this.role)
        }

        let response = await this.$api.register(payload, path, role)
        if (response && response.status === 200) {
          this.success = true
          return
        }
        this.loading = false
        this.error = true
      },
    },
    inject: [
      '$api',
    ],
    mixins: [
      DesignMixin
    ]
  }
</script>

<style lang="scss" scoped>
  .authentication {
    @apply
    pt-4
    flex
    w-full
    h-full
    md:pt-0
    md:items-center
    justify-center;

    .form {
      @apply
      p-6
      w-full
      border
      rounded
      max-w-lg
      shadow-xl;
    }
  }
</style>