<template>
  <navigation-layout>
    <centered-form-layout
      @submit.prevent="submit"
      :title="$t('auth.reset_password')">
      <template v-if="!success">
        <form-row>
          <form-input
            v-model="username">
            {{ $t('generic.username') }}
          </form-input>
        </form-row>
        <form-row>
          <c-button
            class="mt-4"
            type="submit"
            :disabled="loading">
            {{ $t('auth.reset_password') }}
          </c-button>
        </form-row>
      </template>
      <div
        class="py-4"
        v-if="success">
        {{ $t('auth.reset_password_message') }}
      </div>
    </centered-form-layout>
  </navigation-layout>
</template>

<script>
  import DesignMixin from '../../mixin/design'

  export default {
    data() {
      return {
        success: false,
        loading: false,
        username: null,
      }
    },
    async created() {
      await this.applyDesign()
    },
    methods: {
      /**
       * Resets account with given credentials
       * @return {Promise<void>}
       */
      async submit() {
        const jumpURL = this.$route.params.slug
          ? `${window.location.origin}/#/${this.$route.params.slug}/auth/password`
          : `${window.location.origin}/#/auth/password`

        this.loading = true
        this.success = false
        let response = await this.$api.requestPasswordReset(this.username, jumpURL)
        if (response && response.ok) {
          response = await response.json()

          let mailResponse = await this.$api.sendPasswordResetMail({
            email: response.email,
            url: response.htmlaction,
            primaryColor: this.$store.getters['app/primaryColor'],
            tertiaryColor: this.$store.getters['app/tertiaryColor'],
            secondaryColor: this.$store.getters['app/secondaryColor'],
            customerId: this.$helper.extractIdFromSlug(this.$route.params.slug),
          })
          console.log(mailResponse)
          this.success = true
        }
        this.loading = false
      },
    },
    inject: [
      '$api',
      '$helper',
    ],
    mixins: [
      DesignMixin
    ]
  }
</script>